import { AppChooser } from '../../components/app-chooser/AppChooser';
import { App as Main, AppHeader } from './Home.style';

export const Home = () => {
  console.log('Home');

  return (
    <Main>
      <AppHeader>
        <p style={{ margin: '1em 0' }}>
          Here we will have some mechanism to determine what application the user wants to access. For now, we will just
          use a dropdown.
        </p>
        <AppChooser />
      </AppHeader>
    </Main>
  );
};
