import { useLocation } from 'react-router-dom';
import { apps } from '../data/apps';

export const useSelectedApp = () => {
  const { pathname } = useLocation();

  if (pathname) {
    const parts = pathname.split('/').filter(Boolean);

    if (parts.length) {
      const appId = parts[0];

      return apps.find((app) => app.id === appId);
    }
  }
};
